import * as React from 'react';
import ModalSkelleton from '../ModalSkelleton';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectRooms, updateSelectedRoom } from '../../reducer/matrix';
import { hideActiveModal, selectShareMessage } from '../../reducer/app';
import Cross from '../Icons/Cross';
import { useTranslation } from 'react-i18next';
import ListItem from '../ChatRoomsSidebar/ListItem';
import mcli from '../../libs/matrix';
import { EventType } from 'matrix-js-sdk';
import Image from '../ChatMessage/Image';
import Video from '../ChatMessage/Video';
import File from '../ChatMessage/File';
import Audio from '../ChatMessage/Audio';
const ShareMessageModal = () => {
    const dispatch = useAppDispatch();
    const storeRooms = useAppSelector(selectRooms);
    const shareMassage = useAppSelector(selectShareMessage);
    const content = shareMassage.storeEvent.content;
    const [selected, setSelected] = React.useState([]);
    const [rooms, setRooms] = React.useState(storeRooms);
    const { t } = useTranslation();
    const sendShareMessage = async () => {
        if (!selected.length)
            return;
        const content = structuredClone(shareMassage.storeEvent.content);
        content['a.forwarded'] = true;
        selected.forEach(async (room) => {
            await mcli.sendEvent(room.id, EventType.RoomMessage, content);
        });
        if (selected.length === 1) {
            dispatch(updateSelectedRoom(selected[0].id));
        }
        dispatch(hideActiveModal());
    };
    const showContent = (content) => {
        switch (content.msgtype) {
            case 'm.image':
                return (React.createElement("div", { className: "" }, content?.file ? (React.createElement(Image, { name: content.body, file: content.file, callback: () => { }, info: content.info })) : (React.createElement(Image, { name: content.body, url: content.url || '', callback: () => { }, info: content.info }))));
            case 'm.video':
                return (React.createElement("div", { className: "" },
                    React.createElement(Video, { content: content, callback: () => { } })));
            case 'm.file':
                return (React.createElement("div", { className: "w-fit" }, content?.file ? (React.createElement(File, { name: content.body, file: content.file })) : (React.createElement(File, { name: content.body, url: content.url || '' }))));
            case 'm.audio':
                return React.createElement(Audio, { content: content, callback: () => { } });
            default:
                return (React.createElement("div", { className: "relative p-2 max-w-md rounded-lg inline-block bg-sidebar rounded-bl-none w-fit" }, content.body));
        }
    };
    if (!content)
        return React.createElement(React.Fragment, null);
    return (React.createElement(ModalSkelleton, null,
        React.createElement("div", { className: "chat-side-menu flex flex-col justify-between" },
            React.createElement("div", { className: "flex items-center justify-between mb-4" },
                React.createElement("h4", null, t('forward')),
                React.createElement("div", { className: "flex justify-end cursor-pointer", onClick: () => dispatch(hideActiveModal()) },
                    React.createElement(Cross, { className: "w-4 h-4 fill-gray-600" }))),
            React.createElement("div", { className: "flex flex-col mb-4" }, showContent(content)),
            React.createElement("div", null,
                React.createElement("input", { type: "text", placeholder: "Suche nach einem Raum", className: "w-full p-2 border rounded-lg mb-2", onChange: (e) => {
                        setRooms(storeRooms.filter((room) => {
                            const name = room.name.toLowerCase();
                            const searchterm = e.target.value.toLowerCase();
                            return name.includes(searchterm);
                        }));
                    } })),
            React.createElement("div", { className: "h-80 bg-gray-100 overflow-scroll border rounded-lg" },
                React.createElement("ul", { className: "sidebar-entries" }, rooms.length > 0 &&
                    rooms.map((room) => {
                        return (React.createElement(ListItem, { key: room.id, roomId: room.id, onSelect: () => {
                                if (selected.find((sel) => sel.id === room.id)) {
                                    setSelected(selected.filter((sel) => sel.id !== room.id));
                                    return;
                                }
                                setSelected([...selected, room]);
                            }, isSelected: !!selected.find((sel) => sel.id === room.id), onPin: () => { }, isPinned: false, hideOptions: true, isSideBarItem: false }));
                    }))),
            React.createElement("div", { className: "mt-4 flex items-center justify-end w-full" },
                React.createElement("div", { className: "" }, selected.map((room) => {
                    return (React.createElement("span", { className: "text-sm text-gray-400 italic" },
                        room.name,
                        " ",
                        selected.length > 1 && ', '));
                })),
                React.createElement("button", { className: "mapp-button mapp-button-primary ml-4", disabled: !selected.length, onClick: () => {
                        sendShareMessage();
                    } },
                    t('forward'),
                    " (",
                    selected.length,
                    ")")))));
};
export default ShareMessageModal;
