import { RoomEvent } from 'matrix-js-sdk';
import * as React from 'react';
import mcli from '../../../libs/matrix';
import { useAppSelector } from '../../../hooks';
let timestamp = new Date().getTime();
let audio;
const Bell = () => {
    const mute = false;
    const [notifications, setNotifications] = React.useState([]);
    const lastSoundTime = React.useRef(new Date());
    audio = audio || new Audio('/chat_alert_1.mp3');
    const matrix = useAppSelector((state) => state.matrix);
    React.useEffect(() => {
        const update = (event) => {
            if (event.getSender() === mcli.getUserId() || mute)
                return;
            mcli.decryptEventIfNeeded(event).then(() => {
                if (event.getTs() > timestamp) {
                    setNotifications([
                        ...notifications,
                        {
                            time: event.getDate() || new Date(),
                            text: event.getContent().body,
                            eventId: event.getId(),
                        },
                    ]);
                    const roomId = event.getRoomId();
                    const room = matrix.rooms.find((room) => room.id === roomId);
                    if (room && event.sender && roomId) {
                        const currentDate = new Date();
                        const roomPushRule = mcli.getRoomPushRule('global', roomId);
                        const dontNotify = roomPushRule?.actions[0] === 'dont_notify';
                        if (dontNotify)
                            return;
                        if ((currentDate.getTime() -
                            lastSoundTime.current.getTime()) /
                            1000 >
                            5) {
                            if (audio)
                                audio.play();
                            lastSoundTime.current = currentDate;
                            const roomName = event.sender.name === room.name
                                ? ''
                                : `(${room.name})`;
                            if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                                if (Notification.permission === 'granted') {
                                    new Notification(`${event.sender.name} ${roomName}`, {
                                        body: event.getContent().body,
                                        icon: room.imageUrl || '/icon.png',
                                        badge: room.imageUrl || '/icon.png',
                                    });
                                }
                            }
                            timestamp = event.getTs();
                        }
                    }
                }
            });
        };
        if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            Notification.requestPermission();
        }
        mcli.on(RoomEvent.Timeline, update);
        return () => {
            mcli.removeListener(RoomEvent.Timeline, update);
        };
    }, []);
    return React.createElement(React.Fragment, null);
};
export default Bell;
