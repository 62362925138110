import * as React from 'react';
import Check from '../Icons/check';
import mcli from '../../libs/matrix';
import LetterAvatar from '../LetterAvatar/LetterAvatar';
const RoomUserListItem = ({ item, id, imgUrl, name, selectAction, selectedId, }) => {
    return (React.createElement("div", { key: id, className: "flex items-center justify-between min-h-[3rem] hover:bg-primary-light cursor-pointer p-2", onClick: () => selectAction(item) },
        React.createElement("div", { className: "flex items-center" },
            React.createElement("div", null, imgUrl ? (React.createElement("img", { src: mcli.mxcUrlToHttp(imgUrl) || imgUrl, className: "rounded-full w-8 h-8" })) : (React.createElement(LetterAvatar, { name: name.substring(0, 1) ?? '' }))),
            React.createElement("div", { className: "ml-6" }, name)),
        selectedId === id && (React.createElement("div", null,
            React.createElement(Check, { className: "w-5 h-5 fill-primary-full" })))));
};
export default RoomUserListItem;
